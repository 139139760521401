<template>
<div class="tw-flex tw-w-full lg:tw-px-32 tw-px-5 tw-flex-col">
  <h6 class="community tw-mt-10 lg:tw-hidden">Settings</h6>
  <v-tabs
      fixed-tabs
      align-with-title
      v-model="tab" class="tw-mt-10 tw-pb-5 lg:tw-pb-0">

    <v-tab class="tw-justify-start switch-header tw-p-1 ">
      <v-icon class="tw-mr-2">mdi-account</v-icon>
      Profile
    </v-tab>
    <v-tab class="tw-justify-start switch-header tw-p-1">
      <icons class="tw-mr-2" name="key"  :color="tab === 1 ? '#004AAD' : '#989CA0'"/>
      API Token
    </v-tab>
    <v-tab class="tw-justify-start switch-header tw-p-1">
      <icons class="tw-mr-2" name="switch"  :color="tab === 2 ? '#004AAD' : '#989CA0'"/>
      Deactivate Account
    </v-tab>
    <v-tab class="switch-header tw-p-1 tw-justify-start">
      <icons class="tw-mr-2" name="change-password" :color="tab === 3 ? '#004AAD' : '#989CA0'"/>
      Change Password
    </v-tab>
      <v-tab-item   class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-pt-10" >
        <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
          <div class="tw-flex tw-flex-col tw-w-auto  tw-pb-5 lg:tw-pb-0">
            <p class="desc-text">Company Logo</p>
            <button class="button-logo-input" type="button"  :class="image ? 'border-0' : ''">
              <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;" v-if="logoImageLoading"></v-progress-circular>
              <img :src="developerData.logo" alt="" class="button-logo-img" v-if="developerData.logo">
              <img :src="image" alt="" class="button-logo-img" v-else-if="logoImage">
              <img src="../assets/addImage.svg" alt="" class="button-logo-input-img" v-else>
            </button>
            <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
          </div>
          <div class="tw-flex tw-flex-col tw-w-auto">
            <p class="desc-text">CAC Certificate</p>
            <button class="button-logo-input" type="button"  :class="image ? 'border-0' : ''">
              <v-progress-circular :size="100" :width="3" :color="color" indeterminate style="position: absolute;" v-if="logoImageLoading"></v-progress-circular>
              <img :src="developerData.cacCertificate" alt="" class="button-logo-img" v-if="developerData.cacCertificate">
              <img :src="image" alt="" class="button-logo-img" v-else-if="logoImage">
              <img src="../assets/addImage.svg" alt="" class="button-logo-input-img" v-else>
            </button>
            <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
          </div>
        </div>
        <v-row class="tw-flex-col lg:tw-flex-row tw-mt-10">
          <v-col sm="12" lg="6">
            <p class="desc-text">First Name</p>
            <v-text-field solo hide-details placeholder="Chioma"  readonly v-model="developerData.firstname"></v-text-field>
          </v-col>

          <v-col sm="12" lg="6">
            <p class="desc-text">Last Name</p>
            <v-text-field solo hide-details placeholder="Ekong" readonly v-model="developerData.lastname"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="tw-flex-col lg:tw-flex-row lg:tw-pt-10">
          <v-col sm="12" lg="6">
            <p class="desc-text">Email</p>
            <v-text-field solo hide-details placeholder="example@roadpadi.com" readonly type="email" v-model="developerData.email"></v-text-field>
          </v-col>
          <v-col sm="12" lg="6">
            <p class="desc-text">Phone Number</p>
            <phone-number :phone-number-exist="developerData.phoneNumber" :readonly="true"/>
          </v-col>
        </v-row>
        <div class="tw-hidden tw-w-full tw-justify-end tw-mt-10 tw-py-3">
          <v-btn text class="save">Save</v-btn>
        </div>
      </v-tab-item>


      <v-tab-item class="tw-flex tw-w-full tw-flex-col  lg:tw-pt-20 tw-pt-10" >
        <div class="tw-hidden tw-w-full tw-justify-end tw-mt-10 tw-py-3">
          <v-btn text class="save" style="font-weight: bold" @click="generateApiKey" :loading="regenerateLoading">Renew API Key</v-btn>
        </div>
        <v-card flat class="card tw-mt-10 tw-py-10 lg:tw-px-14 tw-px-5">
          <h6 class="card-header tw-flex tw-flex-row tw-items-center"> <icons name="key-rotate" class="tw-mr-2"></icons> Authorization</h6>
          <div class="card-inner tw-mt-5 tw-p-3 lg:tw-flex tw-flex-col">
            <h6 class="link tw-cursor-pointer">Server Url : {{authServer}}/realms/nomadicpod/protocol/openid-connect/token</h6>
             <div class="tw-mt-5"></div>
            <h6 class="link tw-cursor-pointer">Email : Your email Address</h6>
            <div class="tw-mt-5"></div>
            <h6 class="link tw-cursor-pointer">Password : Your password</h6>
          </div>
        </v-card>

        <v-card flat class="card tw-mt-10 tw-py-10 lg:tw-px-14 tw-px-5">
          <h6 class="card-header"> <v-icon color="#004AAD" class="tw-mr-2">mdi-layers</v-icon>Resource</h6>
          <div class="card-inner tw-mt-5 tw-p-3 lg:tw-flex tw-flex-col">
            <h6 class="link tw-cursor-pointer">Server : {{resourceServer}}</h6>
            <div class="tw-mt-5"></div>
            <h6 class="link tw-cursor-pointer">API Key : {{developerData.apiKey}}</h6>
          </div>
        </v-card>
      </v-tab-item>

    <v-tab-item   class="tw-flex tw-w-full tw-flex-col lg:tw-px-28 lg:tw-pt-20 tw-pt-10" >
      <v-row class="tw-flex-col lg:tw-flex-row tw-mt-10">
        <v-col sm="12" >
          <p class="desc-text">Why do you want to Deactivate your account?</p>
          <v-textarea rows="5" solo hide-details placeholder="Reasons" ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="tw-flex-col lg:tw-flex-row lg:tw-pt-10">
        <v-col sm="12" lg="6">
          <p class="desc-text">Email</p>
          <v-text-field solo hide-details placeholder="sorry@example.com"  type="email"></v-text-field>
        </v-col>
        <v-col sm="12" lg="6">
          <p class="desc-text">Password</p>
          <v-text-field solo hide-details placeholder="Password"  type="password"></v-text-field>
        </v-col>
      </v-row>
      <div class="tw-hidden tw-w-full tw-justify-end tw-mt-10 tw-py-3">
        <v-btn text class="save" style="font-weight: bold">Deactivate Account</v-btn>
      </div>
    </v-tab-item>

    <v-tab-item   class="tw-flex tw-w-full  tw-justify-center  lg:tw-pt-20 tw-pt-10" >
      <div class="tw-flex tw-justify-center tw-w-full ">
        <validation-observer ref="observer" v-slot="{ handleSubmit }" class="tw-w-1/2">
          <form @submit.prevent="handleSubmit(changePassword)">
            <div class="tw-w-full tw-flex-col">
              <v-col sm="12">
                <ValidationProvider
                    name="Old Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                >
                  <p class="desc-text">Old Password</p>
                  <v-text-field
                      solo
                      hide-details
                      placeholder="Old password"
                      :append-icon="oldPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (oldPasswordValue = !oldPasswordValue)"
                      :type="oldPasswordValue ? 'text' : 'password'"
                      v-model="passwordData.oldPassword"

                  ></v-text-field>
                  <span class="err">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col sm="12">
                <ValidationProvider
                    name="New Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                >
                  <p class="desc-text">New Password</p>
                  <v-text-field
                      solo
                      hide-details
                      placeholder="New password"
                      v-model="passwordData.newPassword"
                      :append-icon="newPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (newPasswordValue = !newPasswordValue)"
                      :type="newPasswordValue ? 'text' : 'password'"
                  ></v-text-field>
                  <span class="err">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col sm="12">
                <ValidationProvider
                    name="Confirm Password"
                    rules="required"
                    v-slot="{ classes, errors }"
                >
                  <p class="desc-text">Confirm Password</p>
                  <v-text-field
                      solo
                      hide-details
                      placeholder="Confirm password"
                      v-model="passwordData.confirmPassword"
                      :append-icon="confirmPasswordValue ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (confirmPasswordValue = !confirmPasswordValue)"
                      :type="confirmPasswordValue ? 'text' : 'password'"
                  ></v-text-field>
                  <span class="err">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <div class="tw-flex tw-w-full tw-justify-end tw-mt-10 tw-py-3">
                <v-btn
                    text
                    class="save"
                    type="submit"
                    style="font-weight: bold"
                    :loading="ispasswordUpdateLoading"
                >Change Password</v-btn
                >
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </v-tab-item>
  </v-tabs>
  <modal  />
</div>
</template>

<script>
import Icons from "../components/reuseables/Icons";
import {uploadToCloudinary, regenerateApiKey, updatePassword} from "@/services/api/APIService";
import Modal from "../components/reuseables/Modal";
import {mapGetters} from "vuex";
import PhoneNumber from "@/components/reuseables/PhoneNumber.vue";
import {logout} from "@/services/api/AuthApiService";
export default {
  name: "DeveloperSetting",
  components: {PhoneNumber, Modal, Icons},
  data(){
    return{
      tab : null,
      logoImage: '',
      image: '',

      logoImageLoading: false,
      color: '#1E5770',
      baseUrl: "",
      regenerateLoading: false,
      passwordData:{},
      oldPasswordValue : false,
      newPasswordValue: false,
      confirmPasswordValue: false,
      passwordUpdateLoading: false,
      authServer: "",
      resourceServer:""
    }
  },
  watch:{

  },
  computed:{
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
    },
    ...mapGetters("developerData",["developerData"])
  },
  methods:{
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event){
      const files = event.target.files[0]
      try {
        this.logoImageLoading = true
        const fileReader =  new FileReader()
        fileReader.addEventListener('load', async () => {
          this.developerData.logo = ""
          this.image = fileReader.result
          this.logoImage = files.name
          let newData = {
            file: this.image,
            upload_preset: this.preset
          }
          const response = await uploadToCloudinary(newData)
          this.developerData.logo = response.data.url
          console.log(this.developerData)
          this.logoImageLoading = false
        })
        fileReader.readAsDataURL(files)
      } catch (err) {
        console.log(err.response)
      }

    },
    generateApiKey(){
      this.regenerateLoading = true
      let data = {}
      data.apiKey = this.developerData.apiKey
      regenerateApiKey(data).then(async ()=>{
        let userData = JSON.parse(sessionStorage.getItem("userData"))
        await  this.$store.dispatch("developerData/setDeveloperData",userData.id)
            .then(() => this.regenerateLoading = false).finally(()=> this.regenerateLoading = false)
      }).finally(() => this.regenerateLoading = false)
    },
    async changePassword() {
      if (this.passwordData.newPassword === this.passwordData.confirmPassword) {
        this.passwordUpdateLoading = true;
        this.passwordData.email = this.developerData.companyEmail;
        await updatePassword(this.passwordData)
            .then(() => {
              this.passwordData = {};
              this.passwordUpdateLoading = false;
              logout()
            })
            .catch((err) => {
              this.passwordUpdateLoading = false;
              this.$displaySnackbar({
                message: err.toString(),
                success: false,
              });
            });
      } else {
        this.$displaySnackbar({
          message: "New and Confirm Password do not match",
          success: false,
        });
      }
    },
  },
  created() {
    this.authServer = process.env.VUE_APP_AUTH_BASE_URL
    this.resourceServer = process.env.VUE_APP_RESOURCE_SERVER_URL

  }
}
</script>

<style scoped lang="scss">
.switch-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}

::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.v-tabs--fixed-tabs > .v-tabs-bar .v-tab {
  @media screen and (max-width: 768px) {
    width: max-content !important;
    margin-right: 1rem !important;
  }
}

::v-deep .v-slide-group__content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.desc-text {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #4F4F4F;
  margin-bottom: 20px;
}
.save{
  background: #004AAD;
  border: 1px solid rgba(229, 229, 229, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 135px !important;
  color: #FFFFFF;
  text-transform: none;
  height: 50px !important;
}

.card{
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 100%;
}

.card-inner{
  background: #FDFFFC;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  min-height: 89px;

}

.card-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(29, 38, 45, 0.83);
}
.link{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #004AAD;
  flex-wrap: wrap;
  flex-direction: row;
}

.button-logo-input .button-logo-img {
  width: auto;
  height: 131px;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: auto;
  height: 131px;
}

.v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tab:first-child, .v-application--is-ltr .v-tabs--align-with-title > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing) > .v-slide-group__wrapper > .v-tabs-bar__content > .v-tabs-slider-wrapper + .v-tab {
  margin-left: 0!important;
}

.err {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: red;
}
</style>